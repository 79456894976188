import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getDatabase, ref, update } from 'firebase/database';

const messaging = getMessaging();
const database = getDatabase();

// Configuration des notifications
export const initializeNotifications = async (userId) => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      // Obtenir le token FCM
      const token = await getToken(messaging, {
        vapidKey: 'BPYfFKqoFzh-7_5qn_kqz4Zz4y1b8jGcq9QqeXRh6VXGhonQPxQM9-2rY6jh4X3q9X4vG8XzGhmW_3q9X4vG8'
      });

      // Sauvegarder le token dans la base de données
      const userTokenRef = ref(database, `user-tokens/${userId}`);
      await update(userTokenRef, { fcmToken: token });

      // Configurer la réception des messages
      onMessage(messaging, (payload) => {
        new Notification(payload.notification.title, {
          body: payload.notification.body,
          icon: '/notification-icon.png'
        });
      });

      return true;
    }
    return false;
  } catch (error) {
    console.error('Error initializing notifications:', error);
    return false;
  }
};

// Envoi de notification pour un nouveau message
export const sendMessageNotification = async (receiverId, message, rideInfo) => {
  try {
    const userTokenRef = ref(database, `user-tokens/${receiverId}`);
    // Implémenter l'envoi de notification via Cloud Functions
    // Cette partie nécessitera une fonction Cloud Firebase séparée
    return true;
  } catch (error) {
    console.error('Error sending notification:', error);
    return false;
  }
};
