import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  VStack,
  Input,
  IconButton,
  useToast,
  Text,
  HStack,
  Button,
  useColorModeValue,
  Wrap,
  WrapItem,
  Tooltip,
  Avatar,
  Spinner,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPaperPlane,
  faSmile,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import { QUICK_MESSAGES, sendMessage, getMessages, markMessagesAsRead } from '../services/chat.service';
import { auth } from '../firebase.config';
import { useAuthState } from 'react-firebase-hooks/auth';

const RideMessages = () => {
  const { rideId } = useParams();
  const [user, loading] = useAuthState(auth);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [sending, setSending] = useState(false);
  const messagesEndRef = useRef(null);
  const toast = useToast();
  const navigate = useNavigate();
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  useEffect(() => {
    if (loading) return;
    if (!user) {
      navigate('/login');
      return;
    }
    loadMessages();
  }, [user, loading, rideId]);

  const loadMessages = async () => {
    if (!user || !rideId) return;

    try {
      const fetchedMessages = await getMessages(rideId);
      setMessages(fetchedMessages);
      await markMessagesAsRead(rideId, user.email);
      scrollToBottom();
    } catch (error) {
      console.error('Error loading messages:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de charger les messages',
        status: 'error',
        duration: 3000,
      });
    }
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim() || !user) return;

    setSending(true);
    try {
      await sendMessage(rideId, user.email, newMessage.trim());
      setNewMessage('');
      await loadMessages();
    } catch (error) {
      console.error('Error sending message:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible d\'envoyer le message',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setSending(false);
    }
  };

  const handleQuickMessage = async (message) => {
    if (!user) return;

    setSending(true);
    try {
      await sendMessage(rideId, user.email, message);
      await loadMessages();
    } catch (error) {
      console.error('Error sending quick message:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible d\'envoyer le message rapide',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setSending(false);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" h="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box p={4} h="100vh">
      <HStack mb={4} align="center">
        <IconButton
          icon={<FontAwesomeIcon icon={faArrowLeft} />}
          onClick={() => navigate('/messages')}
          variant="ghost"
          aria-label="Retour aux messages"
          size="md"
        />
        <Text fontSize="xl" fontWeight="bold">Messages du trajet</Text>
      </HStack>

      <VStack
        spacing={4}
        h="calc(100vh - 180px)"
        bg={bgColor}
        borderRadius="lg"
        borderWidth="1px"
        borderColor={borderColor}
        p={4}
      >
        {/* Quick Messages */}
        <Wrap spacing={2} mb={4}>
          {QUICK_MESSAGES.map((msg) => (
            <WrapItem key={msg.id}>
              <Tooltip label={msg.text}>
                <IconButton
                  icon={<Text>{msg.icon}</Text>}
                  onClick={() => handleQuickMessage(msg.text)}
                  isDisabled={sending}
                  variant="outline"
                />
              </Tooltip>
            </WrapItem>
          ))}
        </Wrap>

        {/* Messages List */}
        <VStack
          flex={1}
          spacing={4}
          overflowY="auto"
          w="100%"
          p={2}
        >
          {messages.map((message) => (
            <Box
              key={message.id}
              alignSelf={message.senderEmail === user?.email ? 'flex-end' : 'flex-start'}
              maxW="80%"
              bg={message.senderEmail === user?.email ? 'blue.500' : 'gray.100'}
              color={message.senderEmail === user?.email ? 'white' : 'black'}
              p={3}
              borderRadius="lg"
            >
              <Text>{message.text}</Text>
              <Text fontSize="xs" opacity={0.8} mt={1}>
                {new Date(message.timestamp).toLocaleTimeString()}
              </Text>
            </Box>
          ))}
          <div ref={messagesEndRef} />
        </VStack>

        {/* Message Input */}
        <HStack w="100%" spacing={2}>
          <Input
            placeholder="Votre message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          />
          <IconButton
            icon={<FontAwesomeIcon icon={faPaperPlane} />}
            onClick={handleSendMessage}
            isLoading={sending}
            colorScheme="blue"
            aria-label="Envoyer"
          />
        </HStack>
      </VStack>
    </Box>
  );
};

export default RideMessages;
