import { getDatabase, ref, push, get, query, orderByChild, equalTo, set } from 'firebase/database';
import { db as firestoreDb } from '../firebase.config';
import { collection, getDocs, where, query as firestoreQuery } from 'firebase/firestore';
import { sendMessageNotification } from './notification.service';
import { getAuth } from 'firebase/auth';

const database = getDatabase();
const auth = getAuth();

export const QUICK_MESSAGES = [
  {
    id: 'arrival',
    text: "Je suis arrivé(e) au point de rendez-vous",
    icon: '🚗'
  },
  {
    id: 'delay',
    text: "Je serai en retard d'environ 5-10 minutes",
    icon: '⏰'
  },
  {
    id: 'location',
    text: "Pouvez-vous partager votre position en temps réel ?",
    icon: '📍'
  },
  {
    id: 'luggage',
    text: "J'ai une valise de taille moyenne",
    icon: '🧳'
  },
  {
    id: 'contact',
    text: "Voici mon numéro de téléphone : ",
    icon: '📱'
  }
];

export const getUserRides = async (userEmail) => {
  try {
    // Récupérer les trajets où l'utilisateur est participant
    const ridesRef = collection(firestoreDb, 'rides');
    const participantQuery = firestoreQuery(
      ridesRef,
      where('participants', 'array-contains', userEmail)
    );
    
    const creatorQuery = firestoreQuery(
      ridesRef,
      where('createdBy', '==', userEmail)
    );

    const [participantSnap, creatorSnap] = await Promise.all([
      getDocs(participantQuery),
      getDocs(creatorQuery)
    ]);

    const participantRides = participantSnap.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      role: 'participant'
    }));

    const creatorRides = creatorSnap.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      role: 'creator'
    }));

    return [...participantRides, ...creatorRides];
  } catch (error) {
    console.error('Error getting user rides:', error);
    return [];
  }
};

export const sendMessage = async (rideId, userEmail, message) => {
  try {
    const currentUser = auth.currentUser;
    if (!currentUser || currentUser.email !== userEmail) {
      throw new Error('User not authenticated or email mismatch');
    }

    const messagesRef = ref(database, `ride-messages/${rideId}`);
    const newMessageRef = push(messagesRef);
    
    const messageData = {
      text: message,
      senderEmail: userEmail,
      timestamp: Date.now(),
      status: {
        sent: Date.now()
      }
    };

    await set(newMessageRef, messageData);
    return true;
  } catch (error) {
    console.error('Error sending message:', error);
    return false;
  }
};

export const updateMessageStatus = async (rideId, messageId, status) => {
  try {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error('User not authenticated');
    }

    const messageRef = ref(database, `ride-messages/${rideId}/${messageId}/status/${status}`);
    await set(messageRef, Date.now());
    return true;
  } catch (error) {
    console.error('Error updating message status:', error);
    return false;
  }
};

export const getMessages = async (rideId) => {
  try {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error('User not authenticated');
    }

    const messagesRef = ref(database, `ride-messages/${rideId}`);
    const snapshot = await get(messagesRef);
    
    if (snapshot.exists()) {
      const messages = [];
      snapshot.forEach((childSnapshot) => {
        messages.push({
          id: childSnapshot.key,
          ...childSnapshot.val()
        });
      });
      return messages.sort((a, b) => {
        const timeA = a.timestamp || 0;
        const timeB = b.timestamp || 0;
        return timeA - timeB;
      });
    }
    return [];
  } catch (error) {
    console.error('Error getting messages:', error);
    return [];
  }
};

export const markMessagesAsRead = async (rideId, userEmail) => {
  try {
    const currentUser = auth.currentUser;
    if (!currentUser || currentUser.email !== userEmail) {
      throw new Error('User not authenticated or email mismatch');
    }

    const messagesRef = ref(database, `ride-messages/${rideId}`);
    const snapshot = await get(messagesRef);
    
    if (snapshot.exists()) {
      const updates = [];
      
      snapshot.forEach((childSnapshot) => {
        const message = childSnapshot.val();
        if (message.senderEmail !== userEmail && (!message.status || !message.status.read)) {
          updates.push(set(
            ref(database, `ride-messages/${rideId}/${childSnapshot.key}/status/read`),
            Date.now()
          ));
        }
      });
      
      if (updates.length > 0) {
        await Promise.all(updates);
      }
    }
  } catch (error) {
    console.error('Error marking messages as read:', error);
  }
};

export const getUnreadMessageCount = async (rideId, userEmail) => {
  try {
    const currentUser = auth.currentUser;
    if (!currentUser || currentUser.email !== userEmail) {
      throw new Error('User not authenticated or email mismatch');
    }

    const messagesRef = ref(database, `ride-messages/${rideId}`);
    const snapshot = await get(messagesRef);
    let count = 0;
    
    if (snapshot.exists()) {
      snapshot.forEach((childSnapshot) => {
        const message = childSnapshot.val();
        if (message.senderEmail !== userEmail && (!message.status || !message.status.read)) {
          count++;
        }
      });
    }
    
    return count;
  } catch (error) {
    console.error('Error getting unread message count:', error);
    return 0;
  }
};
